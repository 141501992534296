import { getIsMobileApp } from "./getIsMobileApp";

const getMobileAppTncLink = () => {
  if (typeof window !== "undefined" && !!window.ReactNativeWebView) {
    return window.ReactNativeWebView.tncLink;
  }

  return;
};

export const useMobileAppTncLinkOverride = ({
  defaultTncLink,
}: {
  defaultTncLink: string;
}): { tncLink: string } => {
  const isMobileApp = getIsMobileApp();
  const mobileTncLink = getMobileAppTncLink();

  if (!isMobileApp || !mobileTncLink) {
    return { tncLink: defaultTncLink };
  }

  return { tncLink: mobileTncLink };
};
