import { asReportable, onError } from "@integration/bugsnag/csr";
import { CustomError } from "@integration/bugsnag/error-types";

export const couldBeWebView = () => {
  if (typeof window === "undefined") {
    return false;
  }
  try {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroidWebView = userAgent.includes("wv");
    const isIOSWebView = /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(
      userAgent,
    );
    // Additional checks for specific WebView implementations
    const isWebViewSpecific =
      window.ReactNativeWebView !== undefined ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).webkit?.messageHandlers?.reactNative !== undefined;
    return isAndroidWebView || isIOSWebView || isWebViewSpecific;
  } catch (e) {
    const err = asReportable(e);
    onError(
      new CustomError({ name: "WebviewCheckFailed", message: err.message }),
    );
    return false;
  }
};
