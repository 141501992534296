import { clientSideEnvVariable } from "@client-env";
import { couldBeWebView } from "@integration/web-view";

const DEV_ENV = clientSideEnvVariable("DEV_ENV") === "true";

export const navigateToUrl = (
  url: string,
  target?: "_self" | "_blank",
  options?: { noreferrer?: boolean },
) => {
  const isWebview = couldBeWebView();
  if (url.startsWith("https://app.") && DEV_ENV) {
    url = url.replace("https://app.", "https://app-dev.");
  }
  window.open(
    url,
    isWebview ? "_self" : target ? target : "_blank",
    options?.noreferrer ? "noopener noreferrer" : undefined,
  );
};
